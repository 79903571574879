import axios from "axios";
import store from "@/store";
import config from "@/libs/config";
import Cookies from "js-cookie";
import { ElMessage } from "element-plus";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? config.baseUrl.dev
    : config.baseUrl.pro;

class HttpRequest {
  constructor() {
    this.baseUrl = baseUrl;
    this.queue = {};
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        // authorization: "Bearer " + store.state.token,
      },
    };
    return config;
  }
  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        // 请求成功时
        // 添加全局的loading...

        if (!Object.keys(this.queue).length) {
          // Spin.show() // 不建议开启，因为界面不友好
        }
        this.queue[url] = true;

        if (store.state.token) {
          config.headers.common["Authorization"] =
            "Bearer " + store.state.token;
        }
        return config;
      },
      (error) => {
        // 请求失败的时候
        return Promise.reject(error);
      }
    );

    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        if (res.data.code === 4001) {
          Cookies.set("bnxc_token", "");
          store.commit("setLoginBox", true);
        }

        this.destroy(url);
        const { data, status } = res;
        return { data, status };
      },
      (error) => {
        this.destroy(url);
        let errorInfo = error.response;
        switch (errorInfo.status) {
          case 404:
            ElMessage.error({ message: "请求404!" });
            break;
          case 500:
            ElMessage.error({ message: "请求500!" });
            break;
          default:
            ElMessage.error({ message: "请检查网络!" });
            break;
        }

        if (!errorInfo) {
          const {
            request: { statusText, status },
            config,
          } = JSON.parse(JSON.stringify(error));
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url },
          };
        }
        // addErrorLog(errorInfo)
        return Promise.reject(error);
      }
    );
  }
  request(options) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}
export default HttpRequest;
