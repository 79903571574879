import axios from "@/libs/api.request";

// get方式
export const getData = ({ url, data }) => {
  return axios.request({
    url: url,
    method: "get",
    params: data,
  });
};

// post方式
export const postData = ({ url, data }) => {
  return axios.request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// delete方式
export const deleteData = ({ url }) => {
  return axios.request({
    url: url,
    method: "delete",
  });
};

// put方式
export const putData = ({ url, data }) => {
  return axios.request({
    url: url,
    method: "put",
    data: data,
  });
};

// oss 获取签名
export const getSignature = (url) => {
  return axios.request({
    url: url,
    method: "GET",
    params: {},
  });
};
