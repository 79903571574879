export default {
    // 配置显示在浏览器标签的title
    title: "百纳芯城",
    // token在Cookie中存储的天数，默认1天
    cookieExpires: 3650,
    // api请求基础路径
    baseUrl: {
        // dev: "http://ic.dev.api.ebaina.com/",
        // pro: "https://chip.api.ebaina.com/",
        dev: "https://chip.api.ebaina.com/",
        pro: "https://chip.api.ebaina.com/",
    },
    // 默认打开的首页的路由name值，默认为home
    homeName: "home",
};
