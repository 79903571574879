import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "element-plus/dist/index.css";
import "./index.less";
import './assets/iconfont.css'
import './assets/iconfont_color.css'
import htmlToPdf from "./libs/htmlToPdf.js"

createApp(App).use(store).use(router).use(ElementPlus, {locale}).use(htmlToPdf).mount("#app");



var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?2fa2a54a5d4a3f55b266093f5a0aeb29";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();