import { createRouter, createWebHistory } from "vue-router";

const routes = [
    // 官网首页
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
    },

    // 个人中心
    {
        path: "/user",
        name: "user",
        component: () => import("@/views/User.vue"),
    },
    {
        path:"/log",
        name:"log",
        component:() => import("@/views/Log.vue")
    },
    // 搜索页
    {
        path: "/search",
        name: "search",
        component: () => import("@/views/Search.vue"),
    },
    // 产品列表页
    {
        path: "/product",
        name: "product",
        component: () => import("@/views/Product.vue"),
    },
    // 详情页
    {
        path: "/detail",
        name: "detail",
        component: () => import("@/views/Detail.vue"),
    },
    // 购物车
    {
        path: "/car",
        name: "car",
        component: () => import("@/views/Car.vue"),
    },
    // 订单支付页
    {
        path: "/pay",
        name: "pay",
        component: () => import("@/views/Pay.vue"),
    },
    // qq登录
    {
        path:"/qq",
        name:"qq",
        component: () => import("@/views/QQ.vue")
    },
    // 注册
    {   
        path:"/register",
        name:"register",
        component: () => import('@/views/Register.vue')
    },
    // 帮助
    {
        path: "/help",
        name: "help",
        component: () => import('@/views/Help.vue')
    },
    // 预览
    {
        path:'/preview',
        name:'/preview',
        component: () => import('@/views/Preview.vue')
    },
    // smt
    {
        path: '/smt',
        name: '/smt',
        component: () => import('@/views/Smt.vue')
    },
    {
        path: '/smt_issue',
        name: '/smt_issue',
        component: () => import('@/views/Smt_issue.vue')
    },
    {
        path: '/smt_flow',
        name: '/smt_flow',
        component: () => import('@/views/Smt_flow.vue')
    }
    // 404
    // {
    //     path: "/:pathMatch(.*)",
    //     name: "404",
    //     component: () => import('@/views/Smt.vue')
    // },
];

const router = createRouter({
    mode:'history',
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (window._hmt) {
        if (to.path) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath])
        }
    }
    next()
})

export default router;



